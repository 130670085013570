const hebrewLocale = {
    "navbar": {
        "about_us": "מי אנחנו",
        "activity": "פעילות",
        "packages": "חבילות",
        "contact_us": "יצירת קשר",
        "donate": "לתרומה"
    },
    "about_us_content": ""
};

export default hebrewLocale;